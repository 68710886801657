.ui-layout {
  &__children {
    height: 92vh;
    overflow: auto;
    background-color: #dbdbdb;
  }
  &__admin {
    background-color: #f3f1f1;
  }
}
