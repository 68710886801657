.login-modal {
  &__header {
    background: #f96e2a;
  }
  &__body {
    background: #f3f1f1;
    input::placeholder {
      font-size: 14px;
    }
    &--danger {
      font-size: 14px;
    }
  }
}
