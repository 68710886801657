.add-prefecture-modal {
  &__header {
    background-color: #f96e2a;
  }
  &__body {
    input::placeholder {
      font-size: 14px;
    }
    &--url {
      display: flex;
      align-items: center;
      gap: 2px;
      & > span {
        flex-shrink: 0;
        white-space: nowrap;
        background-color: #e0e0e0;
        padding: 0px 4px;
        border-radius: 4px;
      }
    }
    &--subject {
      display: flex;
      align-items: center;
      gap: 8px;
      button {
        width: 40px;
        &.btn-add {
          background-color: #338f84;
          border-color: #338f84;
          &:hover,
          &:active {
            background-color: #285751 !important;
            border-color: #285751 !important;
          }
        }
      }
    }
  }
}
