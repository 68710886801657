.confirm-delete-area-modal {
  .modal-sm {
    --bs-modal-width: 520px;
  }
  &__header {
    background-color: #f96e2a;
  }
  &__body {
    .action {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      button:first-child {
        background-color: #338f84;
        border-color: #338f84;

        &:hover {
          background-color: #285751;
          border-color: #285751;
        }
        &:disabled {
          background-color: #62a59d;
          border-color: #62a59d;
          opacity: 0.65;
        }
      }
    }
  }
}
